import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = props => {
  const router = useRouter()
  const params = Object.assign(props)
  const state = reactive({
    userId: params.userId
  })
  const fnLogin = async () => {
    await router.replace({ path: '/login' })
  }
  return { ...toRefs(state), fnLogin }
}
