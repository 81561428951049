<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>비밀번호 재설정</span>
          </div>
          <div class="inform center">
            <div>
              <span class="color">{{ userId }}</span
              >님의<br />
              비밀번호가 변경되었습니다.
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnLogin">로그인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-find-id-done-index',
  props: {
    userId: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
